// noinspection JSNonASCIINames

import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { getStringStyleParamsValue } from './stylesParams';
import {
  AlignmentOptions,
  ImagePositionOptions,
  ImageShapeOptions,
  ImageSizeOptions,
  ServiceListLayoutOptions,
} from '../../types/types';
import {
  getAlternateImageInfoSidesDefaultValue,
  getCardSpacingDefaultValue,
  getFlipImageAndTextRatioDefaultValue,
  getGridCardSpacingDefaultValue,
  getIsServiceImageVisibleDefaultValue,
  getMaxCardsPerRowDefaultValue,
  getServiceImagePositionDefaultValue,
  getServiceImageShapeDefaultValue,
  getServiceImageSizeDefaultValue,
  getServiceListLayoutDefaultValue,
  getServicesDividerWidthDefaultValue,
  getStripCardSpacingDefaultValue,
  getTextAlignmentDefaultValue,
  getTitleAlignmentDefaultValue,
} from './settingsParams-utils';
import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  GRID_CARD_MIN_WIDTH,
  MOBILE_CARD_MIN_WIDTH,
} from '../../consts';

type CustomCssVarsArgs = Parameters<CustomCssVarsFn>[0];

const getServiceImagePositionFromTpaData = (
  tpaData: any,
  isMobile: boolean,
) => {
  const serviceImagePosition = isMobile
    ? ((tpaData?.['serviceImagePosition▶︎m'] ||
        tpaData?.serviceImagePosition) as ImagePositionOptions)
    : (tpaData?.serviceImagePosition as ImagePositionOptions);
  if (serviceImagePosition) {
    return serviceImagePosition;
  }
  return getServiceImagePositionDefaultValue();
};

const getServiceImageShapeFromTpaData = (tpaData: any, isMobile: boolean) => {
  const serviceImageShape = isMobile
    ? ((tpaData?.['serviceImageShape▶︎m'] ||
        tpaData?.serviceImageShape) as ImageShapeOptions)
    : (tpaData?.serviceImageShape as ImageShapeOptions);
  if (serviceImageShape) {
    return serviceImageShape;
  }
  const preset =
    tpaData?.[isMobile ? 'presetId▶︎m' : 'presetId'] ??
    BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID;
  return getServiceImageShapeDefaultValue(preset, isMobile);
};

const getAlternateImageInfoSidesFromTpaData = (tpaData: any) => {
  const alternateImageInfoSides =
    tpaData?.alternateImageInfoSides as ImagePositionOptions;
  return alternateImageInfoSides || getAlternateImageInfoSidesDefaultValue();
};

const getServiceTextAlignmentFromTpaData = (
  tpaData: any,
  isMobile: boolean,
) => {
  const textAlignment = isMobile
    ? ((tpaData?.['textAlignment▶︎m'] ||
        tpaData?.textAlignment) as unknown as AlignmentOptions)
    : (tpaData?.textAlignment as unknown as AlignmentOptions);
  const preset =
    tpaData?.[isMobile ? 'presetId▶︎m' : 'presetId'] ??
    BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID;
  return textAlignment || getTextAlignmentDefaultValue(preset, isMobile);
};

const getServiceTitleAlignmentFromTpaData = (
  tpaData: any,
  isMobile: boolean,
) => {
  const titleAlignment = isMobile
    ? ((tpaData?.['titleAlignment▶︎m'] ||
        tpaData?.titleAlignment) as unknown as AlignmentOptions)
    : (tpaData?.titleAlignment as unknown as AlignmentOptions);

  return titleAlignment || getTitleAlignmentDefaultValue();
};

const getServiceImageSizeFromTpaData = (tpaData: any, isMobile: boolean) => {
  const serviceImageSize = isMobile
    ? tpaData?.['serviceImageSize▶︎m']
    : (tpaData?.serviceImageSize as unknown as ImageSizeOptions);
  return serviceImageSize || getServiceImageSizeDefaultValue(isMobile);
};

const getMaxCardsPerRowFromTpaData = (tpaData: any, isMobile: boolean) => {
  const maxCardsPerRow = (isMobile
    ? tpaData?.['cardsPerRow▶︎m']
    : tpaData?.cardsPerRow) as unknown as number;
  return maxCardsPerRow || getMaxCardsPerRowDefaultValue(isMobile);
};

const getServiceListLayoutFromTpaData = (tpaData: any, isMobile: boolean) => {
  const serviceLiseLayout = tpaData?.[
    isMobile ? 'serviceListLayout▶︎m' : 'serviceListLayout'
  ] as unknown as ServiceListLayoutOptions;
  if (serviceLiseLayout) {
    return serviceLiseLayout;
  }
  const preset =
    tpaData?.[isMobile ? 'presetId▶︎m' : 'presetId'] ??
    BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID;
  return getServiceListLayoutDefaultValue(preset, isMobile);
};

const getGridCardSpacingFromTpaData = (tpaData: any, isMobile: boolean) => {
  const gridCardsSpacing = (isMobile
    ? tpaData?.['gridCardsSpacing▶︎m'] || tpaData?.gridCardsSpacing
    : tpaData?.gridCardsSpacing) as unknown as number;
  return gridCardsSpacing || getGridCardSpacingDefaultValue(isMobile);
};

const getFlipImageAndTextRatioFromTpaData = (
  tpaData: any,
  isMobile: boolean,
) => {
  const flipImageAndTextRatio = (isMobile
    ? tpaData?.['flipImageAndTextRatio▶︎m'] || tpaData?.flipImageAndTextRatio
    : tpaData?.flipImageAndTextRatio) as unknown as boolean;
  if (flipImageAndTextRatio !== undefined) {
    return flipImageAndTextRatio;
  }
  const preset =
    tpaData?.[isMobile ? 'presetId▶︎m' : 'presetId'] ??
    BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID;
  return getFlipImageAndTextRatioDefaultValue(preset);
};

const getStripCardSpacingFromTpaData = (tpaData: any, isMobile: boolean) => {
  const stripCardSpacing = (isMobile
    ? tpaData?.['stripCardSpacing▶︎m'] || tpaData?.stripCardSpacing
    : tpaData?.stripCardSpacing) as unknown as number;
  return stripCardSpacing || getStripCardSpacingDefaultValue();
};

const getCardSpacingFromTpaData = (tpaData: any, isMobile: boolean) => {
  const cardSpacing = (isMobile
    ? tpaData?.['cardSpacing▶︎m'] || tpaData?.cardSpacing
    : tpaData?.cardSpacing) as unknown as number;
  return cardSpacing || getCardSpacingDefaultValue();
};

const calculateCardSpacing = (
  layout: ServiceListLayoutOptions,
  customCssVarsArgs: CustomCssVarsArgs,
): number => {
  const { styleParams, tpaData, isMobile } = customCssVarsArgs;
  switch (layout) {
    case ServiceListLayoutOptions.GRID:
      return (
        styleParams.numbers.gridCardsSpacing ??
        getGridCardSpacingFromTpaData(tpaData, isMobile)
      );
    case ServiceListLayoutOptions.STRIP:
      return (
        styleParams.numbers.stripCardSpacing ??
        getStripCardSpacingFromTpaData(tpaData, isMobile)
      );
    default:
      return (
        styleParams.numbers.cardSpacing ??
        getCardSpacingFromTpaData(tpaData, isMobile)
      );
  }
};

const calculateMaxCardsPerRow = (
  layout: ServiceListLayoutOptions,
  customCssVarsArgs: CustomCssVarsArgs,
): number => {
  const { styleParams, isMobile } = customCssVarsArgs;
  if (!isMobile && layout === ServiceListLayoutOptions.GRID) {
    return (
      styleParams.numbers.maxCardsPerRow ||
      getMaxCardsPerRowFromTpaData(
        customCssVarsArgs.tpaData,
        customCssVarsArgs.isMobile,
      )
    );
  }
  return 1;
};

export const getIsServiceImageVisibleFromTpaDate = (
  tpaData: any,
  isMobile: boolean,
) => {
  const isServiceImageVisible = (isMobile
    ? tpaData?.['isServiceImageVisible▶︎m'] || tpaData?.isServiceImageVisible
    : tpaData?.isServiceImageVisible) as unknown as boolean;
  const preset =
    tpaData?.[isMobile ? 'presetId▶︎m' : 'presetId'] ??
    BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID;

  return (
    isServiceImageVisible ??
    getIsServiceImageVisibleDefaultValue(preset, isMobile)
  );
};

export const getServicesDividerWidthFromTpaDate = (
  tpaData: any,
  isMobile: boolean,
) => {
  const servicesDividerWidth = (isMobile
    ? tpaData?.['servicesDividerWidth▶︎m'] ?? tpaData?.servicesDividerWidth
    : tpaData?.servicesDividerWidth) as unknown as number;
  return servicesDividerWidth ?? getServicesDividerWidthDefaultValue();
};

export const cssVarsGenerator = (customCssVarsArgs: CustomCssVarsArgs) => {
  const { isMobile, isRTL, styleParams, tpaData } = customCssVarsArgs;
  const numericStyles = styleParams.numbers;
  const isServiceImageVisible =
    styleParams.booleans.isServiceImageVisible ??
    getIsServiceImageVisibleFromTpaDate(tpaData, isMobile);
  const serviceImagePosition =
    (getStringStyleParamsValue(
      styleParams.strings.serviceImagePosition,
    ) as ImagePositionOptions) ??
    getServiceImagePositionFromTpaData(tpaData, isMobile);
  const serviceImageShape =
    (getStringStyleParamsValue(
      styleParams.strings.serviceImageShape,
    ) as ImageShapeOptions) ??
    getServiceImageShapeFromTpaData(tpaData, isMobile);
  const isServiceImageOnRight =
    serviceImagePosition === ImagePositionOptions.RIGHT;
  const serviceTextAlignment =
    (getStringStyleParamsValue(
      styleParams.strings.serviceTextAlignment,
    ) as AlignmentOptions) ??
    getServiceTextAlignmentFromTpaData(tpaData, isMobile);
  const serviceTitleAlignment =
    (getStringStyleParamsValue(
      styleParams.strings.titleAlignment,
    ) as AlignmentOptions) ??
    getServiceTitleAlignmentFromTpaData(tpaData, isMobile);
  const serviceImageSize =
    (getStringStyleParamsValue(
      styleParams.strings.serviceImageSize,
    ) as ImageSizeOptions) ?? getServiceImageSizeFromTpaData(tpaData, isMobile);
  const serviceListLayout =
    (getStringStyleParamsValue(
      isMobile
        ? styleParams.strings.serviceListMobileLayout
        : styleParams.strings.serviceListLayout,
    ) as ServiceListLayoutOptions) ??
    getServiceListLayoutFromTpaData(tpaData, isMobile);
  const isGridLayout = serviceListLayout === ServiceListLayoutOptions.GRID;
  const isStripLayout = serviceListLayout === ServiceListLayoutOptions.STRIP;

  const alternateImageInfoSides =
    isStripLayout || isGridLayout
      ? false
      : styleParams.booleans.alternateImageInfoSides ??
        getAlternateImageInfoSidesFromTpaData(tpaData);

  const maxCardsPerRow = calculateMaxCardsPerRow(
    serviceListLayout,
    customCssVarsArgs,
  );
  const cardsSpacing = calculateCardSpacing(
    serviceListLayout,
    customCssVarsArgs,
  );
  const displayDividersBetweenCards = isStripLayout;
  const dividerWidth =
    numericStyles.servciesDividerWidthInStrip ??
    getServicesDividerWidthFromTpaDate(tpaData, isMobile);
  const gridPadding = isStripLayout
    ? `${cardsSpacing / 2 + dividerWidth}px`
    : 'unset';
  const isRoundImage = serviceImageShape === ImageShapeOptions.ROUND;
  const gridGapForLayout = isStripLayout
    ? cardsSpacing + dividerWidth
    : cardsSpacing;

  const flipImageAndTextRatioFromTpaData = getFlipImageAndTextRatioFromTpaData(
    tpaData,
    isMobile,
  );

  const displayStyle = (show: boolean) => (show ? 'block' : 'none');

  function getStripMediaSize() {
    switch (serviceImageSize) {
      case ImageSizeOptions.SMALL:
        return isMobile ? '40px' : '84px';
      case ImageSizeOptions.MEDIUM:
        return isMobile ? '56px' : '110px';
      case ImageSizeOptions.LARGE:
        return isMobile ? '84px' : '140px';
    }
  }

  let mediaRatio = numericStyles.imageAndTextRatio;

  if (flipImageAndTextRatioFromTpaData) {
    mediaRatio = 100 - mediaRatio;
  }

  if (isRTL ? !isServiceImageOnRight : isServiceImageOnRight) {
    mediaRatio = 100 - mediaRatio;
  }

  const imageAspectRatio = isMobile
    ? serviceImageShape === ImageShapeOptions.SQUARE
      ? 1
      : 1.52
    : isGridLayout
    ? serviceImageShape === ImageShapeOptions.SQUARE
      ? 1
      : 1.5
    : 0;

  return {
    explorePlansVisibility: () => {
      return {
        explorePlansPlaceholderVisibility: displayStyle(
          (isGridLayout && !isMobile) || isStripLayout,
        ),
        explorePlansMarginTop: isStripLayout ? 0 : '16px',
      };
    },
    bodyWidgetGrid: () => {
      return {
        bodyMaxWidth: isGridLayout ? '616px' : 'unset',
        bodyWidth: isGridLayout ? '100%' : 'unset',
        bodyMarginLeft: isGridLayout ? 'auto' : 'unset',
        bodyMarginRight: isGridLayout ? 'auto' : 'unset',
        cardMaxWidth: isGridLayout && !isMobile ? '616px' : `100%`,
        cardMinWidth: `${
          isMobile ? MOBILE_CARD_MIN_WIDTH : GRID_CARD_MIN_WIDTH
        }px`,
      };
    },
    gridCardsAndSpacing: () => {
      return {
        cardsPerRow: maxCardsPerRow,
        cardsSpacing: `${cardsSpacing}px`,
        cardsDividerVisibility: displayStyle(displayDividersBetweenCards),
        dividerWidth,
        gridPadding,
        gridGapForLayout,
        topPositionForDivider: cardsSpacing / 2 + dividerWidth,
      };
    },
    classicImagePosition: () => {
      if (isGridLayout) {
        return { evenImageDirection: 'column', oddImageDirection: 'column' };
      }

      if (isStripLayout) {
        return { evenImageDirection: 'row', oddImageDirection: 'row' };
      }

      const imageOnLeft = isRTL ? 'row-reverse' : 'row';
      const imageOnRight = isRTL ? 'row' : 'row-reverse';
      if (alternateImageInfoSides) {
        return isServiceImageOnRight
          ? { evenImageDirection: imageOnRight, oddImageDirection: imageOnLeft }
          : {
              evenImageDirection: imageOnLeft,
              oddImageDirection: imageOnRight,
            };
      } else {
        return isServiceImageOnRight
          ? {
              evenImageDirection: imageOnRight,
              oddImageDirection: imageOnRight,
            }
          : { evenImageDirection: imageOnLeft, oddImageDirection: imageOnLeft };
      }
    },
    overlappingSpecialAttributes: () =>
      serviceListLayout === ServiceListLayoutOptions.OVERLAPPING
        ? {
            gridTemplateRows: isServiceImageVisible ? '1fr 8fr 1fr' : '1fr',
            infoBorderWidth: numericStyles.borderWidth,
            infoZIndex: '20',
            rootBackgroundColor: 'transparent',
          }
        : {
            gridTemplateRows: 'unset',
            infoBorderWidth: 0,
            infoZIndex: 'auto',
            rootBackgroundColor: styleParams.colors.infoBackgroundColor,
          },
    overlappingImagePosition: () => {
      if (serviceListLayout !== ServiceListLayoutOptions.OVERLAPPING) {
        const stripMargin = isMobile ? 16 : 32;
        const margin = isStripLayout
          ? isRTL
            ? `0 0 0 ${stripMargin}px`
            : `0 ${stripMargin}px 0 0`
          : 0;
        return {
          evenOverlappingGridArea: 'unset',
          oddOverlappingGridArea: 'unset',
          evenOverlappingImageMargin: margin,
          oddOverlappingImageMargin: margin,
          evenOverlappingTemplateColumns: 'unset',
          oddOverlappingTemplateColumns: 'unset',
        };
      }
      const overlappingGridAreaInfoBeforeImage = isServiceImageVisible
        ? `". media"
        "info media"
        ". media"`
        : `"info"`;

      const overlappingGridAreaImageBeforeInfo = isServiceImageVisible
        ? `"media margin-top"
        "media info"
        "media margin-bottom"`
        : `"info"`;

      const overlappingGridAreaImageInRight = isRTL
        ? overlappingGridAreaImageBeforeInfo
        : overlappingGridAreaInfoBeforeImage;
      const overlappingGridAreaImageInLeft = isRTL
        ? overlappingGridAreaInfoBeforeImage
        : overlappingGridAreaImageBeforeInfo;

      const onlyLeftImageMargin = isServiceImageVisible ? '0 -60px 0 0' : 0;
      const onlyRightImageMargin = isServiceImageVisible ? '0 0 0 -60px' : 0;
      if (alternateImageInfoSides) {
        return isServiceImageOnRight
          ? {
              evenOverlappingGridArea: overlappingGridAreaImageInRight,
              oddOverlappingGridArea: overlappingGridAreaImageInLeft,
              evenOverlappingImageMargin: onlyRightImageMargin,
              oddOverlappingImageMargin: onlyLeftImageMargin,
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '1fr',
            }
          : {
              evenOverlappingGridArea: overlappingGridAreaImageInLeft,
              oddOverlappingGridArea: overlappingGridAreaImageInRight,
              evenOverlappingImageMargin: onlyLeftImageMargin,
              oddOverlappingImageMargin: onlyRightImageMargin,
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '1fr',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr',
            };
      } else {
        return isServiceImageOnRight
          ? {
              evenOverlappingGridArea: overlappingGridAreaImageInRight,
              oddOverlappingGridArea: overlappingGridAreaImageInRight,
              evenOverlappingImageMargin: onlyRightImageMargin,
              oddOverlappingImageMargin: onlyRightImageMargin,
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '50% 1fr'
                : '1fr',
            }
          : {
              evenOverlappingGridArea: overlappingGridAreaImageInLeft,
              oddOverlappingGridArea: overlappingGridAreaImageInLeft,
              evenOverlappingImageMargin: onlyLeftImageMargin,
              oddOverlappingImageMargin: onlyLeftImageMargin,
              evenOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '1fr',
              oddOverlappingTemplateColumns: isServiceImageVisible
                ? '1fr 50%'
                : '1fr',
            };
      }
    },
    serviceDetailsTextAlignment: () => {
      if (isStripLayout) {
        return isRTL
          ? {
              serviceDetailsTextAlign: 'right',
              serviceDetailsMarginLeft: 'auto',
              serviceDetailsMarginRight: 0,
            }
          : {
              serviceDetailsTextAlign: 'left',
              serviceDetailsMarginLeft: 0,
              serviceDetailsMarginRight: 'auto',
            };
      }
      switch (serviceTextAlignment) {
        case AlignmentOptions.CENTER:
          return {
            serviceDetailsTextAlign: 'center',
            serviceDetailsMarginLeft: 'auto',
            serviceDetailsMarginRight: 'auto',
          };
        case AlignmentOptions.LEFT:
          return {
            serviceDetailsTextAlign: 'left',
            serviceDetailsMarginLeft: 0,
            serviceDetailsMarginRight: 'auto',
          };
        case AlignmentOptions.RIGHT:
          return {
            serviceDetailsTextAlign: 'right',
            serviceDetailsMarginLeft: 'auto',
            serviceDetailsMarginRight: 0,
          };
      }
    },
    serviceTitleAlignment: () => {
      switch (serviceTitleAlignment) {
        case AlignmentOptions.CENTER:
          return {
            titlePaddingLeft: '10%',
            titlePaddingRight: '10%',
            titleTextAlign: 'center',
          };
        case AlignmentOptions.LEFT:
          return {
            titlePaddingLeft: 'initial',
            titlePaddingRight: '20%',
            titleTextAlign: 'left',
          };
        case AlignmentOptions.RIGHT:
          return {
            titlePaddingLeft: '20%',
            titlePaddingRight: 'initial',
            titleTextAlign: 'right',
          };
      }
    },
    serviceImageSize: () => ({
      serviceImageSize: isStripLayout ? getStripMediaSize() : '100%',
    }),
    mediaSize: () => {
      switch (serviceListLayout) {
        case ServiceListLayoutOptions.STRIP:
          const size = getStripMediaSize();
          return { mediaWidth: size, mediaHeight: size };

        case ServiceListLayoutOptions.OVERLAPPING:
          return { mediaWidth: 'auto', mediaHeight: '100%' };

        default:
          return { mediaWidth: '100%', mediaHeight: '100%' };
      }
    },
    mediaPadding: () => ({
      imagePaddingTop: isStripLayout
        ? 0
        : imageAspectRatio
        ? `${Math.round(100 / imageAspectRatio)}%`
        : `${isGridLayout ? 100 : 0}%`,
    }),
    gridLayoutCard: () => {
      switch (serviceListLayout) {
        case ServiceListLayoutOptions.GRID:
          return {
            cardDisplay: 'flex',
            layoutCardMinWidth: '130px',
            infoWidth: 'inherit',
            layoutVerticalPadding: isMobile
              ? numericStyles.gridVerticalPadding
              : numericStyles.gridVerticalPadding - numericStyles.borderWidth,
            layoutSidePadding: isMobile
              ? numericStyles.gridSidePadding
              : numericStyles.gridSidePadding - numericStyles.borderWidth,
            mediaRatio: 0,
            infoRatio: 1,
            infoOverflow: 'unset',
            imageWrapperRadius: isRoundImage
              ? '50%'
              : `${numericStyles.serviceImageCornerRadius}px`,
            imageCardCornerRadius: 0,
            imageWrapperMargin: isRoundImage
              ? `${32 - numericStyles.borderWidth}px auto 0`
              : `${numericStyles.serviceImagePadding}px`,
            imageWrapperHeight: `calc(100% - ${
              isRoundImage ? 32 : numericStyles.serviceImagePadding * 2
            }px)`,
            imageWrapperWidth: isRoundImage
              ? `calc(${100 / imageAspectRatio}% - 32px)`
              : `calc(100% - ${numericStyles.serviceImagePadding * 2}px)`,
            cardBorderWidth: numericStyles.borderWidth,
            stripCalcIndication: '0px',
            nonStripCalcIndication: '1px',
            mediaFlex: 0,
            infoFlex: 1,
            backgroundOnHover: 'inherit',
            cornerRadius: numericStyles.cornerRadius,
            infoCardCornerRadius: 0,
          };
        case ServiceListLayoutOptions.OVERLAPPING:
          return {
            cardDisplay: 'inline-grid',
            layoutCardMinWidth: '560px',
            infoWidth: 'auto',
            layoutVerticalPadding: numericStyles.overlappingVerticalPadding,
            layoutSidePadding: numericStyles.overlappingSidePadding,
            mediaRatio: `${mediaRatio}%`,
            infoRatio: `${100 - mediaRatio}%`,
            infoOverflow: 'hidden',
            imageWrapperRadius: isRoundImage
              ? '50%'
              : `${numericStyles.serviceImageCornerRadius}px`,
            imageCardCornerRadius: isRoundImage
              ? '50%'
              : `${numericStyles.serviceImageCornerRadius}px`,
            imageWrapperMargin: 0,
            imageWrapperHeight: '100%',
            imageWrapperWidth: '100%',
            cardBorderWidth: 0,
            stripCalcIndication: '0px',
            nonStripCalcIndication: '1px',
            mediaFlex: `${mediaRatio}%`,
            infoFlex: `${100 - mediaRatio}%`,
            backgroundOnHover: 0,
            cornerRadius: 0,
            infoCardCornerRadius: `${numericStyles.cornerRadius}px`,
          };
        case ServiceListLayoutOptions.STRIP:
          return {
            cardDisplay: 'inline-flex',
            layoutCardMinWidth: isMobile ? '280px' : 'auto',
            infoWidth: 'auto',
            layoutVerticalPadding: numericStyles.stripVerticalPadding,
            layoutSidePadding: numericStyles.stripSidePadding,
            mediaRatio: `${mediaRatio}%`,
            infoRatio: `${100 - mediaRatio}%`,
            infoOverflow: 'hidden',
            imageWrapperRadius: isRoundImage
              ? '50%'
              : `${numericStyles.serviceImageCornerRadius}px`,
            imageCardCornerRadius: 0,
            imageWrapperMargin: 0,
            imageWrapperHeight: '100%',
            imageWrapperWidth: '100%',
            cardBorderWidth: numericStyles.stripBorderWidth,
            stripCalcIndication: '1px',
            nonStripCalcIndication: '0px',
            mediaFlex: 'auto 0 1',
            infoFlex: '0% 1 1',
            backgroundOnHover: 'inherit',
            cornerRadius: numericStyles.cornerRadius,
            infoCardCornerRadius: 0,
          };
        default: // CLASSIC
          return {
            cardDisplay: 'inline-flex',
            layoutCardMinWidth: '130px',
            infoWidth: 'auto',
            layoutVerticalPadding: numericStyles.classicVerticalPadding,
            layoutSidePadding: numericStyles.classicSidePadding,
            mediaRatio: `${mediaRatio}%`,
            infoRatio: `${100 - mediaRatio}%`,
            infoOverflow: 'hidden',
            imageWrapperRadius: `${numericStyles.serviceImageCornerRadius}px`,
            imageCardCornerRadius: 0,
            imageWrapperMargin: `${numericStyles.serviceImagePadding}px`,
            imageWrapperHeight: `calc(100% - ${
              numericStyles.serviceImagePadding * 2
            }px)`,
            imageWrapperWidth: `calc(100% - ${
              numericStyles.serviceImagePadding * 2
            }px)`,
            cardBorderWidth: numericStyles.borderWidth,
            stripCalcIndication: '0px',
            nonStripCalcIndication: '1px',
            mediaFlex: `${mediaRatio}%`,
            infoFlex: `${100 - mediaRatio}%`,
            backgroundOnHover: 'inherit',
            cornerRadius: numericStyles.cornerRadius,
            infoCardCornerRadius: 0,
          };
      }
    },
    dividerMargin: () => ({
      serviceInfoDividerMargin: isMobile ? '16px 0' : '24px 0',
    }),
    serviceInfo: () => {
      const { isServiceDividerVisible, isCourseAvailabilityVisible } =
        styleParams.booleans;

      switch (serviceListLayout) {
        case ServiceListLayoutOptions.STRIP: // Horizontal
          return {
            serviceInfoDisplay: 'flex',
            serviceInfoDetailsWrap: 'wrap',
            serviceInfoFlexDirection: 'row',
            serviceInfoMargin: '0 -16px',
            serviceInfoColumnsMargin: '0 24px',
            serviceInfoFirstColumnBasis: 'calc(60% - 16px)',
            serviceInfoDividerDisplay: 'none',
            serviceInfoTitleAreaFlex: '1 calc(60% - 24px)',
            serviceInfoTitleAreaFlexWithNoBookButton: '1 calc(70% - 24px)',
            serviceInfoDetailsMargin: '0 -8px',
            serviceInfoTitleBottom: 0,
            serviceInfoTagLineTopMargin: '8px',
            serviceInfoTagLineBottomMargin: 0,
            serviceInfoButtonMargin: '0 16px',
            serviceInfoButtonWidth: '100%',
            serviceInfoButtonMinWidth: '151px',
            serviceInfoButtonMaxWidth: '154px',
            serviceInfoButtonWhiteSpace: 'normal',
            serviceInfoJustifyContent: 'unset',
            priceAreaMaxHeight: 'unset',
            emptyPriceAreaDisplay: 'none',
            horizontalCourseAvailabilityDisplay: displayStyle(
              isCourseAvailabilityVisible,
            ),
            verticalCourseAvailabilityDisplay: 'none',
            serviceInfoHorizontalColumnMinWidth: '158px',
          };

        default: // Vertical
          return {
            serviceInfoDisplay: isGridLayout ? 'flex' : 'block',
            serviceInfoDetailsWrap: 'nowrap',
            serviceInfoFlexDirection: 'column',
            serviceInfoMargin: 0,
            serviceInfoColumnsMargin: 0,
            serviceInfoFirstColumnBasis: 'unset',
            serviceInfoDividerDisplay: displayStyle(isServiceDividerVisible),
            serviceInfoTitleAreaFlex: 'none',
            serviceInfoTitleAreaFlexWithNoBookButton: 'none',
            serviceInfoDetailsMargin: 0,
            serviceInfoTitleBottom: '0 0 12px 0',
            serviceInfoTagLineTopMargin: '12px',
            serviceInfoTagLineBottomMargin: '12px',
            serviceInfoButtonMargin: `20px 0 0 0`,
            serviceInfoButtonWidth: isMobile ? '100%' : 'unset',
            serviceInfoButtonMinWidth: 'unset',
            serviceInfoButtonMaxWidth: 'unset',
            serviceInfoButtonWhiteSpace: 'nowrap',
            serviceInfoJustifyContent: isGridLayout
              ? 'space-between'
              : 'center',
            priceAreaMaxHeight: 'fit-content',
            emptyPriceAreaDisplay: 'block',
            horizontalCourseAvailabilityDisplay: 'none',
            verticalCourseAvailabilityDisplay: displayStyle(
              isCourseAvailabilityVisible,
            ),
            serviceInfoHorizontalColumnMinWidth: 'unset',
          };
      }
    },
  } satisfies Record<string, () => ReturnType<CustomCssVarsFn>>;
};

export const generateAllCssVars: CustomCssVarsFn = (args) => {
  const cssVarsGenerators = cssVarsGenerator(args);
  type keysType = keyof typeof cssVarsGenerators;
  return (Object.keys(cssVarsGenerators) as keysType[]).reduce((css, key) => {
    const cssGeneratorFn = cssVarsGenerators[key];
    const generatedCss = cssGeneratorFn();
    return { ...css, ...generatedCss };
  }, {});
};
